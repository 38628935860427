.question-container {
    width: 100%;
    /* border: 1px solid #e0e0e0; */
    padding: 5px;
    border-radius: 12px;
    /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    background-color: #fff; */
  }
  
  .rate-container {
    position: absolute;
    right: 5px;
    width: 250px;
    height: 80px;
  }

  .rate-container-bottom {
    opacity: 0;
    width: 250px;
    height: 0px;
  }
  
  .question-text-container-normal {
    color: #030303;
    font-size: 15px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    font-weight: 500;
    line-height: 32px;
    min-height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
  }
  .question-text-container-normal h4 {
    font-size: 15px;
    text-align: center;
    margin-top: 8px;
  }
  .question-text {
    flex: 1;
  }
  
  .options-container {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .question-text-container {
      width: 100%;
    }
    .question-text-container-normal {
      color: #030303;
      font-size: 15px;
      font-family: BentonSans, Arial, Noto Sans, sans-serif;
      font-weight: 500;
      line-height: 32px;
      min-height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }
    .rate-container {
      opacity: 0;
    }
    .rate-container-bottom {
      width: 250px;
      height: 100px;
      opacity: 1;
      align-self: center;
    }
  }
  