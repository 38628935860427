.navbar {
    background-color: #f1eff0;
    color: #000;
    padding: 5px 0;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3); /* Shadow effect */
    margin: 8px;
    position: sticky;
    border-radius: 8px;
    border:1px solid #fff;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
  }
  
  .navbar-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    width: 40px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Adjust spacing */
  }
  
  .navbar-name {
    font-size: 18px;
    color: rgb(27, 134, 210);
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .navbar-button {
    background-color: gold;
    color: #000;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.navbar-button:hover {
    background-color: #EDC967; /* Slightly lighter gold for hover */
    box-shadow: 0 0 15px 5px rgba(237, 201, 103, 0.5); /* Light glowing shadow */
}

  @media (max-width: 768px) {
    .navbar-right{
      display: none;
    }
  }
  