.Input {
    width: 152px;
    height: 50px;
    padding: 0px 8px;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 50px;
    font-family: "Orbitron, sans-serif";
    line-height: 55px;
    outline: none;
    text-align: center;
    animation: siren-animation 0.5s infinite alternate;
    background-color: #ff5c5c;
  }
  
  @keyframes siren-animation {
    0% { background-color: #2b2b2b; }
    50% { background-color: #ff5c5c; }
    100% { background-color: #2b2b2b; }
  }