table {
    width: 100%;
  }
  th,
  td {
    border: 1px solid #ddd;
    text-align: center;
  }
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  caption {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }