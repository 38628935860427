.summary-div-container {
  border: 2px solid #edc967; /* Example border added, change color as needed */
  background-color: #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); /* Add border shadow */
  border-radius: 20px;
  padding: 8px;
  overflow-y: scroll;
}

.summary-heading-text {
  background: linear-gradient(to right, #f27c27, #179949); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
}

.CardBlue {
  height: 170px;
  background-color: #023f81;
  border-radius: 38.06215384615385px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Media query for mobile devices (max-width 768px) */
  @media (max-width: 768px) {
    width: 100%; /* Set width to 100% for mobile */
  }

  /* Media query for desktop devices (min-width 768px) */
  @media (min-width: 768px) {
    width: 80%; /* Set width to 80% for desktop */
  }
}