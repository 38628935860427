/* QuizHistory.css */

.quiz-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 20px;
}

.quiz-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  word-wrap: break-word;
  overflow: hidden;
  box-shadow: #f9f9f9;
}

.test-name {
  color: #030303;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  line-height: 22px;
}

.test-value {
  color: #030303;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  line-height: 22px;
  margin-left: 5px;
}

.test-row {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.page-welcome-text-container {
  padding: 5px;
}

.page-welcome-text-container span {
  display: inline;
}

.page-welcome-text {
  font-family: BentonSans-Bold, Arial, Noto Sans, sans-serif;
  font-size: large;
  font-weight: bold;
}

.page-welcome-text-name {
  background: linear-gradient(to right, #4362dd, #ff7e5f); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline; /* Ensure the span is inline */
  font-size: larger;
}

.page-welcome-text-tagline{
  background: linear-gradient(to right, #f27c27, #179949); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline; /* Ensure the span is inline */
  font-size: large;
}

@media (max-width: 767px) {
  .quiz-container {
    grid-template-columns: 1fr; /* Change to 1 column for smaller screens */
  }

  .page-welcome-text {
    font-size: 16px;
    text-align: justify;
  }
}
