/* General Styles */
.carousel-item img {
  width: 100%; /* Ensure width is always 100% */
  height: 90vh; /* Adjust height as needed */
  object-fit: cover; /* Cover maintains aspect ratio and fills the container */
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 20px;
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  margin-right: 0px;
  left: 0px;
  right: 0px;
}

.carousel-caption h4,
.carousel-caption p {
  color: #fff;
 
}

.button-section {
  display: none; /* Hide by default */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-item img {
    height: 50vh; /* Smaller height for smaller devices */
  }

  .carousel-caption {
    padding: 10px; /* Less padding on smaller devices */
    font-size: 0.9rem; /* Smaller text on smaller devices */
  }

  .button-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Start with center alignment */
    align-items: center; /* Horizontally center the content */
    height: calc(50vh - 40px); /* Adjust based on actual carousel height */
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); /* Elegant light grey gradient */
    text-align: center;
    padding: 20px;
    padding-bottom: 30%; /* Larger bottom padding to push content up */
  }

  .button-section .btn {
    padding: 10px 20px;
    font-size: 1rem;
    margin: 10px; /* Space around buttons */
    width: 80%; /* Adjust width as needed */
  }

  .btn-login {
    background-color: gold;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  .btn-login:hover {
    background-color: #ebcd79;
    color: #000000;
    font-weight: bold;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .steps {
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  .step {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: transform 0.3s;
  }
}
