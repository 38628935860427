.list-container-new {
  max-height: 160px; /* Set maximum height */
  overflow-y: auto; /* Add vertical scroll if content overflows */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  align-items: center;
  justify-content: space-between;
}
.item {
  cursor: "pointer";
  height: 49px;
  padding: "0px 8px";
  border: "0";
  box-sizing: "border-box";
  border-radius: 2px;
  background-color: "#114c8a";
  color: "#ffffff";
  font-size: "20px";
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: 700;
  line-height: "26px";
  outline: "none";
}
@media (max-width: 768px) {
  .list-container {
    max-height: 160px; /* Set maximum height */
    overflow-y: auto; /* Add vertical scroll if content overflows */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    align-items: center;
    justify-content: space-between;
  }
}