.automatic-text-changer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  
  .text-container {
    position: relative;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    font-size: medium;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  