.card {
  width: "95%";
  height: 120px;
  background-color: #303030;
  border-radius: 20px;
  align-self: center;
  color: #ffffff;
  padding: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}
.card-text {
  font-size: 16px;
  align-self: center;
  margin-left: 10px;
  padding: 15px;
  color: #ffffff;
}
.animated-div-active {
  transition: opacity 2s ease; /* Define the easing transition for the animation */
}

.animated-div {
  opacity: 0;
}
.div-row-container-new {
  flex-direction: "row";
  display: "flex";
  justify-content: "space-between";
  margin-top: "5px";
}
.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

.horizontal-list-item {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  background-color: #303030;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.horizontal-list-item:hover {
  background-color: #484848;
}
.div-section-heading {
  color: #b3b3b3;
  font-size: 18px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: 600;
  line-height: 24px;
}
.div-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.button-styles {
  cursor: pointer;
  padding: 5px;
  border: 0;
  box-sizing: border-box;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: bold;
  line-height: 18px;
  text-transform: capitalize;
  outline: none;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  align-self: flex-end;
  width: 30%;
}

.button-styles-generate{
  background: linear-gradient(135deg, #f27c27, #c763d7);
}
.button-styles-pyq{
  background-color: #5c9aeb;
}

.button-styles-pyq:hover{
  background-color: #4c93f0;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.button-styles-generate:hover{
  background: linear-gradient(135deg, #63d774, #f27c27);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.home-page-buttons-div{
  display: flex;
  justify-items: flex-end;
  gap: 20px;
  margin-left: 300px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .card {
    height: 90px;
  }
  .button-styles {
    width: 100%;
    border-radius: 20px;
  }
  .card-text {
    font-size: 14px;
    align-self: center;
    margin-left: 10px;
    padding: 8px;
  }
  .div-row-container {
    flex-direction: column;
  }
  .horizontal-list {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
  }
  .horizontal-list-item {
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: auto;
    width: 100%;
    justify-content: center;
  }

  .home-page-buttons-div{
    justify-content: center;
    gap: 10px;
    margin-left: 2px;
  }
}