.question-container {
  width: 95%;
  box-shadow: inset;
}

.rate-container {
  position: absolute;
  right: 5px;
  width: 250px;
  height: 100px;
}

.textInputExplanation {
  width: 95%;
  min-height: 60px;
  width: 90%;
  align-self: center;
  font-size: 15px;
  margin-left: 2.5%;
  margin-bottom: 8px;
  border-radius: 8px;
  border: #c7c7c7 1px solid;
  padding: 8px;
}
.option-div-container {
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 80px;
  font-size: 14px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  margin-left: 8px;
  margin-right: 8px;
  align-content: center;
  font-weight: 700;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow values as needed */
  border: 1px #c7c7c7 solid;
  cursor: pointer;
}

.option-div-container-selected {
  padding: 8px;
  background-color: #F7EF8A;
  color: #000000;
  border-radius: 10px;
  min-width: 80px;
  font-size: 14px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  margin-left: 8px;
  margin-right: 8px;
  align-content: center;
  font-weight: 700;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow values as needed */
  border: 1px #c7c7c7 solid;
  cursor: pointer;
}

.report-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  opacity: 0;
  height: 0px;
}
.report-container-active {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: opacity 2s ease;
}
.option-buttons {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.explaination {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
}
.explaintext {
  font-size: 14px;
  /* margin-left: 8px; */
  font-family: BentonSans, Arial, Noto Sans, sans-serif;

}
.separator {
  height: 1.5px;
  background-color: #c7c7c7;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 8px;
}
.button-styles-analysis {
  cursor: pointer;
  padding: 5px;
  border: 0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: gold;
  color: #000000;
  font-size: 16px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: bold;
  line-height: 18px;
  text-transform: capitalize;
  outline: none;
  align-self: center;
  width: 120px;
  /* flex-grow: 1; */
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  /* margin-left: 8%;
  margin-right: 8%; */

}
.div-options {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.question-text-container {
  color: #030303;
  font-size: 15px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: 500;
  line-height: 32px;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.question-text-container h4 {
  font-size: 15px;
  text-align: center;
  margin-top: 8px;
}
.question-text {
  flex: 1;
  margin-left: 3px;
  font-size: 15px;
}

.options-container {
  width: 100%;
}

.icon-div {
  width: 14px;
  height: 14px;
}
.text-div {
  color: "#030303";
  font-size: 13px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: 500;
  line-height: 15px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .question-text-container {
    color: #030303;
    font-size: 15px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    font-weight: 500;
    line-height: 22px;
    min-height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
  }

  .rate-container {
    opacity: 0;
  }
  .rate-container-bottom {
    width: 250px;
    height: 100px;
    opacity: 1;
    align-self: center;
  }
  .icon-div {
    width: 10px;
    height: 10px;
  }
  .text-div {
    font-size: 12px;
  }
}
