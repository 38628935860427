.container {
    display: flex;
    flex-direction: column;
    align-items:baseline;
    padding: 0 10px; /* Added padding for better mobile view */
  }
  
  .optionsContainer {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; Initially, one column for mobile */
    gap: 5;
    /* width: 100%; Full width */
    margin-bottom: 10px;
  }
  
  .option {
    /* background-color: #5d5d5b; */
    color: #000000;
    padding: 5px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 14px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .option-selected {
    background-color: #023f81;
    color: #ffffff;
    padding: 10px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 14px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: row;
  }
  .option-selected-right {
    color: #008000;
    padding: 5px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 14px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    font-weight: bolder;
  }
  .option-selected-wrong {
    color: #ff0000;
    padding: 5px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 14px;
    font-family: BentonSans, Arial, Noto Sans, sans-serif;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    font-weight: bolder;
  }
  .option:hover {
    background-color: #d6d5d5; /* Change the background color on hover */
    color: #000;
  }
  
  /* Media query for desktop screens */
  @media screen and (max-width: 768px) {
    .optionsContainer {
      grid-template-columns: 1fr; /* Two columns for desktop */
    }
    .option {
      color: #000;
      padding: 10px;
      border-radius: 40px;
      cursor: pointer;
      font-size: 14px;
      font-family: BentonSans, Arial, Noto Sans, sans-serif;
      display: flex;
    flex-direction: row;
    }
  }
  