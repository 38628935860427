/* QuizHistory.css */
.quiz-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .quiz-card-full{
    display: grid;
  }
  .quiz-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    word-wrap: break-word;
    overflow: hidden;
    box-shadow: #f9f9f9;
  }
  .test-name {
    color: #030303;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    line-height: 22px;
  }
  .test-value {
    color: #030303;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    line-height: 22px;
    margin-left: 5px;
  }

  .test-row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  @media (max-width: 767px) {
    .quiz-container {
      grid-template-columns: 1fr;
    }
  }
  