.list-container {
  display: flex;
  grid-template-rows: repeat(2, auto); /* Creates two rows */
  grid-auto-flow: column; /* Places new items in columns */
  gap: 5px; /* Optional: adds gap between items */
  padding-left: 5px;
  grid-template-columns: repeat(auto-fill, minmax(min-content, max-content));
  flex-wrap: wrap;
}

.horizontal-list-item-new {
  padding: 2px;
  box-sizing: border-box;
  border: 2px solid #ffd700;
  text-align: start;
  /*background-color: #303030;*/
  border-radius: 30px;
  color: #303030;
  font-size: 14px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  padding-left: 8px;
  padding-right: 5px;
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  justify-content: space-between;
}
.card-holder {
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Apply box shadow for elevation effect */
  transition: box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  margin: 3px 3px 10px 3px;
  padding: 5px 5px 8px 5px;
  background-color: #ffffff;
}

.card-topic:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase box shadow on hover */
}
.horizontal-list-item-new-selected {
  padding: 2px;
  box-sizing: border-box;
  text-align: start;
  background-color: #F7EF8A;
  border-radius: 30px;
  color: #303030;
  font-size: 14px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  padding-left: 8px;
  padding-right: 5px;
  margin-top: 8px;
  border: 2px solid gold;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  justify-content: space-between;
}

.item {
  cursor: pointer;
  height: 49px;
  padding: 0 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #114c8a;
  color: #ffffff;
  font-size: 20px;
  font-family: BentonSans, Arial, Noto Sans, sans-serif;
  font-weight: 700;
  line-height: 26px;
  outline: none;
}

@media (max-width: 768px) {
  /* Hide scrollbar for Webkit (Chrome, Safari) and Edge */
  .list-container::-webkit-scrollbar {
    display: none;
  }

  .list-container {
    display: grid;
  }

  .horizontal-list-item-new{
    width: auto;
    font-size: 12px;
  }

  .horizontal-list-item-new-selected{
    width: auto;
    font-size: 12px;
  }
}
